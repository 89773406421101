import { Component, OnInit, ViewEncapsulation, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { environment } from "../../../environments/environment";
import { SharedService } from '../../shared/services/shared.service';
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class LandingPageComponent implements OnInit, OnDestroy {
  private subscription;
  staticUrlForImgNew: string = environment.staticUrlForImgNew;
  experiencesData;
  highlightsData;
  newsData;
  benefitsData;
  landingBannerData;
  ourWorldHeaderData;
  ourWorldData;
  subscribeData;
  momentsData;
  landingKeyArray;
  accessWithEaseData;
  successfulSubscription: boolean = false;
  subscriptionFlag: boolean = false;
  landingColorList = {
    newsDataTitleColor: "#2C2C2C", newsDataLineColor: "#C69E67", newsDateColor: "#AF8F63", newsCopyColor: "#F5F2ED", newsViewAllLineColor: "#AF8F63", primaryColor: '#C69E67', experiencesDataLineColor: '#C69E67', experiencesDataTitleColor: '#2C2C2C', footerIconColor: '#866D4B', footerHoverColor: '#F5F2ED', footerFillColor: '#FFFFFF', subscribeDataBgColor: "#FFFFFF", subscribeDataTitleColor: "#4B4847", subscribeDataLineColor: "#A67D54", subscribeDotColor: "#A67D54", subscribeLineColor: "#A67D54", subscribeTextColor: "#4B4847", momentsIconColor: "#A67D54", headerDataHoverColor: "#976841"
  };
  landingExpSwiperData = { experiencesSliderName: 'experiences_fraction' };
  landingHeaderList: string | string[];
  showSubHeaderList = false;
  landingEventsData;
  landingNewBannerData;
  landingUpcomingEventData;
  showComponents = [false, false, false, false, false, false]; // Modify as needed
  totalComponents = 6; // Total number of components you want to render
  componentThreshold = 900;

  // exhibitorLogin: string = environment.exhibitorLogin;
  constructor(public _sharedService: SharedService, private titleService: Title, private router: Router,
    private cdr: ChangeDetectorRef
  ) {
    this.landingHeaderList = ["Our World", "Highlights", "Getting Here", "Upcoming"];
    this._sharedService.getCommonService().successfulSubscription.subscribe(
      (data) => {
        this.successfulSubscription = data;
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this._sharedService.callWindowNotificationClose();
      }
    });
  }

  ngOnInit() {
    this.getLandingPageContent();
    this.callLandingCTEvent("Page_Load_JWC", "", "JioWorld Centre");
    if (window.innerWidth < 991) this.showSubHeaderList = true;
    this._sharedService.getCommonService().onSubsciptionPageLoad();
    this._sharedService.getCommonService().subscriptionFlag.subscribe(
      (data) => {
        this.subscriptionFlag = data;
        // console.log("flag: ", this.subscriptionFlag);
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
    this._sharedService.getCommonService().subscriptionDetails.subscribe(
      (data) => {
        // console.log(data);
        this.updateCTInfo(data);
      },
      (error) => {
        console.log("Try after some time..");
      }
    );
  }

  @HostListener('window:scroll', ['$event'])
  onScroll(event: Event): void {
    // Determine the current scroll position
    const scrollPosition = window.scrollY + window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // If we are near the bottom (buffer distance from bottom: 300px)
    if (scrollPosition >= documentHeight - this.componentThreshold) {
      this.loadMoreComponents();
    }
  }

  // Incrementally load components (show their visibility in the array)
  loadMoreComponents() {
    // Find the index of the first component that isn't shown
    const currentIndex = this.showComponents.findIndex((show) => !show);

    if (currentIndex !== -1) {
      const nextComponentCount = 5; // Number of components to load at once

      // Make sure we don't exceed the total number of components
      const nextIndex = Math.min(currentIndex + nextComponentCount, this.totalComponents);

      // Mark the components as visible
      for (let i = currentIndex; i < nextIndex; i++) {
        this.showComponents[i] = true;
      }

      // Trigger change detection to render components
      this.cdr.detectChanges();
    }
  }

  getLandingPageContent() {
    this.landingKeyArray = [
      36387, // Hero Banner
      36007,  // Our World
      37845, // Getting Here(Access it with ease)
      36187, // Experiences
      36269, // Benefits & Previlieges
      36620, // Highlights
      36896, // In The News
      37063, // Subscribe To Our World
      'JWC', // instagram
      316207, // Where Events Come to Life,
      'HOME-HERO-BANNER-NEW', // Hero Banner New
      'JWC-UPCOMING-EVENT'
      //TODO - Old Id's to be deleted after new approach binding
    ];
    this.subscription = this._sharedService.getCommonService().getlandingUrl(this.landingKeyArray).subscribe(
      (data: any) => {
        this.landingBannerData = data[0] ? data[0].data : data[0];
        this.ourWorldData = data[1] ? data[1].data : data[1];
        this.accessWithEaseData = data[2] ? data[2].data : data[2];
        this.experiencesData = data[3] ? data[3].data : data[3];
        this.benefitsData = data[4] ? data[4].data : data[4];
        this.highlightsData = data[5] ? data[5].data : data[5];
        this.newsData = data[6] ? data[6].data : data[6];
        this.subscribeData = data[7] ? data[7].data : data[7];
        this.momentsData = data[8] ? data[8].data : data[8];
        this.landingEventsData = data[9] ? data[9].data : data[9];
        this.landingNewBannerData = data[10] ? data[10].data : data[10];
        this.landingUpcomingEventData = data[11] ? data[11].data : data[11];
        this.landingUpcomingEventData.cards = this.landingUpcomingEventData?.cards?.filter(card => card?.HideEvent?.data === 'false')
        this._sharedService.initializeCustomJs();
        this.landingHeaderList = this._sharedService.subHeaderItems(this.landingHeaderList);
      },
      (error) => {
        console.log("Try after some time..");
      }
    )
  }
  updateCTInfo(data) {
    if (data.data) {
      let emailId = data.data.mailId;
      let updatedProfile = {
        Email: emailId
      };
      this._sharedService.getCleverTapService().createCTUserLogin(updatedProfile);
      // console.log(updatedProfile);
      let eventObj = {};
      if (this.subscriptionFlag) {
        let unsubscriptionLink = data.data.unsubscriptionUrl;
        eventObj = {
          Act: "Y",
          Unsubscribe_link: unsubscriptionLink
        }
      } else {
        eventObj = {
          Act: "N",
          Unsubscribe_link: ""
        }
      }
      this._sharedService.getCleverTapService().recordSubscriptionEvent("Newsletter subscription", eventObj);
      // console.log(eventObj);
    }
  }

  callLandingCTEvent(eveName, secName, linkClicked) {
    this._sharedService.callCTEvent(eveName, secName, '', linkClicked);
  }

  getAutoSliderTime(input: any){
    if (input && input.data)
      return input.data.match(/(\d+)/)? input.data.match(/(\d+)/)[0] * 1000 : 4000;
    else 
      return 4000;  
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
