import { ChangeDetectorRef, Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { MetaService } from './shared/services/meta.service';
import { SharedService } from './shared/services/shared.service';
import { MetaPageKeys } from './shared/shared.constant';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare let fbq: Function;
declare let gtag: Function;
declare function version(): any;


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit {
  consentFlag = 'false';
  title = 'JWC_AJ_JwcUI';
  isSticky;
  pageName;
  notification$: Observable<any>;
  notificationPair: Map<string, string>;
  //phase1 live change
  isNewHF;
  isNewHeaderDesk;
  closeNotificationDiv = false;
  browserVersion: boolean;
  closeExpiredNotificationDiv = false;
  isGuestGuidePage = false;
  islandingPage;
  schemaScript: any;
  metaKeys = MetaPageKeys;
  subscriptionList:any = [];
  constructor(private router: Router,
    private titleService: Title,
    private activatedRoute: ActivatedRoute,
    private _sharedService: SharedService,
    private changeDetector: ChangeDetectorRef,
    public customMetaService: MetaService,
  public http : HttpClient) {
    this.notificationPair = new Map([
      ['3', '199325'],
      //['4', '199325'],
      ['2', '261839'],
      ['1', 'JWC-HOME-NOTIFICATION']
    ]);
    console.log({ checkBrowser: this._sharedService.checkBrowser })
  }
  ngAfterViewChecked() {
    this.changeDetector.detectChanges();
  }
  ngOnInit() {
    if (this._sharedService.checkBrowser) {
      const appTitle = this.titleService.getTitle();
      this.browserVersion = version();
      if (!this.browserVersion) this.router.navigate(['/upgrade-browser']);

      const preferences = this._sharedService?.getCookieService()?.getPreferences();
      if (preferences) this.consentFlag = 'true';
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          let fChild = this.activatedRoute.firstChild;
          // if (preferences?.statistics) {
            gtag('config', 'UA-185010494-1', { 'page_path': event.urlAfterRedirects });
            gtag('config', 'AW-662181025');
            if (preferences?.marketing) { fbq('track', 'PageView'); }
            if (fChild.snapshot.data['gtagConversion']) {
              // Event snippet for Page view_1 conversion page
              if (fChild.snapshot.data['gtagConversion'] == 'page-view') {
                gtag('event', 'conversion', { 'send_to': 'AW-662181025/R2jjCNLb7ooDEKGp4LsC' });
              }
              // Event snippet for Lead conversion page
              else if (fChild.snapshot.data['gtagConversion'] == 'lead') {
                gtag('event', 'conversion', { 'send_to': 'AW-662181025/FI8WCNyO1MgBEKGp4LsC' });
              }
            }
          // }
        }
      });

      this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(() => {
        const child = this.activatedRoute.firstChild;
        const fragment: string = this.activatedRoute.snapshot.fragment;
        if (child.snapshot.data['page'] == 'sign-in') this.getStickyValForSignin();
        else this.isSticky = (child.snapshot.data['sticky']) ? child.snapshot.data['sticky'] : false;
        this.islandingPage = (child.snapshot.data['landingPage']) ? child.snapshot.data['landingPage'] : false;
        // phase1 live changes start
        this.isNewHF = (child.snapshot.data['newHF']) ? child.snapshot.data['newHF'] : false;
        this.isNewHeaderDesk = (child.snapshot.data['newHeaderDesk']) ? child.snapshot.data['newHeaderDesk'] : false;

        if (child.snapshot.data['page']) {
          if (child.snapshot.data['page'] == 'guest-guide') this.isGuestGuidePage = true;
          if (child.snapshot.data['title'] == 'Downloads') {
            if (child.snapshot.params['brand'] == 'jio-world-convention-centre') {
              this.pageName = 'cec'
            } else if (child.snapshot.params['brand'] == 'jio-world-garden') {
              this.pageName = 'garden'
            }
          } else if (child.snapshot.data['page'] == 'sign-in') {
            this.pageName = this._sharedService.getPreviousRouteData();
          } else if (child.snapshot.data['page'] == 'chatbot' || child.snapshot.data['page'] == 'guest-guide' || child.snapshot.data['page'] == 'tickets' || child.snapshot.data['page'] == 'blank') {
            this.pageName = ''
          } else {
            this.pageName = child.snapshot.data['page']
          }
        } else {
          this.pageName = 'home'
        }
        if (this.notificationPair.get(child.snapshot.data['notificationId']) !== undefined && ((fragment && !this.closeNotificationDiv) || (!fragment && !this.closeNotificationDiv))) {
          this.notification$ = this._sharedService.getCommonService().getlandingData(this.notificationPair.get(child.snapshot.data['notificationId']))
        } else {
          this.notification$ = null
        }
        return appTitle;
      })
      ).subscribe((ttl: string) => {
        // this.titleService.setTitle(ttl);
      });

      this._sharedService.getCommonService().getlandingUrl(['GLOBAL-CONFIGURATIONS'])
        .pipe(map((data: any) => data[0]?.data?.cards))
        .subscribe((data: any) => { this._sharedService.setConfigData(data); })
    }
  //  this.getEnv();
  }

  closeNotificationEvent(event) {
    this.closeNotificationDiv = event
    // console.log("this.closeNotificationDiv", this.closeNotificationDiv)
  }
  closeExpiredNotificationEvent(ev) {
    this.closeExpiredNotificationDiv = ev
    // console.log("this.closeExpiredNotificationDiv", this.closeExpiredNotificationDiv)
  }
  getStickyValForSignin() {
    let url = this._sharedService.getPreviousUrl();
    if (url) this.isSticky = url.state.root.firstChild.data.sticky ? url.state.root.firstChild.data.sticky : false;
  }

  
  getEnv() {
    // URL for the environment configuration file
    let url = '/assets/config/environment.json';

    // Headers to prevent caching
    let headers = {
        'Cache-Control': 'no-store, no-cache, must-revalidate, post-check=0, pre-check=0'
    };

    // Make an HTTP GET request to fetch the environment configuration
    this.subscriptionList.push(
        this.http.get(url, { headers: headers }).subscribe(
            (res) => {
                // Iterate over the keys in the response object and update the environment
                for (let item of Object.keys(res)) {
                    environment[item] = res[item];
                }

                // Log the environment for debugging purposes
                console.log("========env", environment);
                if (this._sharedService.checkBrowser) {
                  const appTitle = this.titleService.getTitle();
                  this.browserVersion = version();
                  if (!this.browserVersion) this.router.navigate(['/upgrade-browser']);
            
                  const preferences = this._sharedService?.getCookieService()?.getPreferences();
                  if (preferences) this.consentFlag = 'true';
                  this.router.events.subscribe(event => {
                    if (event instanceof NavigationEnd) {
                      let fChild = this.activatedRoute.firstChild;
                      // if (preferences?.statistics) {
                        gtag('config', 'UA-185010494-1', { 'page_path': event.urlAfterRedirects });
                        gtag('config', 'AW-662181025');
                        if (preferences?.marketing) { fbq('track', 'PageView'); }
                        if (fChild.snapshot.data['gtagConversion']) {
                          // Event snippet for Page view_1 conversion page
                          if (fChild.snapshot.data['gtagConversion'] == 'page-view') {
                            gtag('event', 'conversion', { 'send_to': 'AW-662181025/R2jjCNLb7ooDEKGp4LsC' });
                          }
                          // Event snippet for Lead conversion page
                          else if (fChild.snapshot.data['gtagConversion'] == 'lead') {
                            gtag('event', 'conversion', { 'send_to': 'AW-662181025/FI8WCNyO1MgBEKGp4LsC' });
                          }
                        }
                      // }
                    }
                  });
            
                  this.router.events.pipe(filter(event => event instanceof NavigationEnd), map(() => {
                    const child = this.activatedRoute.firstChild;
                    const fragment: string = this.activatedRoute.snapshot.fragment;
                    if (child.snapshot.data['page'] == 'sign-in') this.getStickyValForSignin();
                    else this.isSticky = (child.snapshot.data['sticky']) ? child.snapshot.data['sticky'] : false;
                    this.islandingPage = (child.snapshot.data['landingPage']) ? child.snapshot.data['landingPage'] : false;
                    // phase1 live changes start
                    this.isNewHF = (child.snapshot.data['newHF']) ? child.snapshot.data['newHF'] : false;
                    this.isNewHeaderDesk = (child.snapshot.data['newHeaderDesk']) ? child.snapshot.data['newHeaderDesk'] : false;
            
                    if (child.snapshot.data['page']) {
                      if (child.snapshot.data['page'] == 'guest-guide') this.isGuestGuidePage = true;
                      if (child.snapshot.data['title'] == 'Downloads') {
                        if (child.snapshot.params['brand'] == 'jio-world-convention-centre') {
                          this.pageName = 'cec'
                        } else if (child.snapshot.params['brand'] == 'jio-world-garden') {
                          this.pageName = 'garden'
                        }
                      } else if (child.snapshot.data['page'] == 'sign-in') {
                        this.pageName = this._sharedService.getPreviousRouteData();
                      } else if (child.snapshot.data['page'] == 'chatbot' || child.snapshot.data['page'] == 'guest-guide' || child.snapshot.data['page'] == 'tickets' || child.snapshot.data['page'] == 'blank') {
                        this.pageName = ''
                      } else {
                        this.pageName = child.snapshot.data['page']
                      }
                    } else {
                      this.pageName = 'home'
                    }
                    if (this.notificationPair.get(child.snapshot.data['notificationId']) !== undefined && ((fragment && !this.closeNotificationDiv) || (!fragment && !this.closeNotificationDiv))) {
                      this.notification$ = this._sharedService.getCommonService().getlandingData(this.notificationPair.get(child.snapshot.data['notificationId']))
                    } else {
                      this.notification$ = null
                    }
                    return appTitle;
                  })
                  ).subscribe((ttl: string) => {
                    // this.titleService.setTitle(ttl);
                  });
            
                  this._sharedService.getCommonService().getlandingUrl(['GLOBAL-CONFIGURATIONS'])
                    .pipe(map((data: any) => data[0]?.data?.cards))
                    .subscribe((data: any) => { this._sharedService.setConfigData(data); })
                }

              
            },
            (error) => {
                // Handle any errors that occur during the HTTP request
                console.log(error);
            }
        )
    );
}

}
